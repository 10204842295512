@-webkit-keyframes recordSpin {
  from {-webkit-transform: rotate(0deg);}
  to   {-webkit-transform: rotate(359deg);}
}

@-webkit-keyframes moveToneArmOn {
  from {-webkit-transform: rotate(0deg);}
  to   {-webkit-transform: rotate(25deg);}
}

@-webkit-keyframes moveToneArmOff {
  from {-webkit-transform: rotate(25deg);}
  to   {-webkit-transform: rotate(0deg);}
}
