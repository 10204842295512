$jw-primary-base: (
  50 : #e4e2e6,
  100 : #bcb7bf,
  200 : #8f8795,
  300 : #62566b,
  400 : #f90,
  500 : #1e0e2b,
  600 : #1a0c26,
  700 : #160a20,
  800 : #12081a,
  900 : #0a0410,
  A100 : #71fbb2,
  A200 : #201b3f,
  A400 : #a73af3,
  A700 : #eb34f6,
  contrast:
    (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff
    )
);

$jw-accent-base: (
  50 : #fde2ef,
  100 : #fbb7d7,
  200 : #f888bc,
  300 : #f558a1,
  400 : #f2348d,
  500 : #f01079,
  600 : #ee0e71,
  700 : #ec0c66,
  800 : #e9095c,
  900 : #e50549,
  A100 : #ffffff,
  A200 : #ffdae3,
  A400 : #ffa7bd,
  A700 : #ff8daa,
  contrast:
    (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000
    )
);

$default-margin: 10px;
$default-padding: 10px;
$default-border-radius: 6px;
$large-radius: 25px;

$nav-height: 82px;
$default-black-bg: rgba(0,0,0,0.2);
$orange: #f90;
