.side-menu-layout {
  //display: grid;
  //grid-template-columns: 1fr;
}

.side-menu-layout__content {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.side-menu-layout__children {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.side-menu-layout__nav-link {
  padding: 1rem;
  border-bottom: 1px solid rgba(#f90, 0.4);
  display: flex;
  align-items: center;
}