@import "../../../scss/variables";
@import "../../../scss/animations";
@import "../../../scss/backgrounds";

.track-player {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 8fr 3fr;
  align-items: center;

  .record {
    width: 50px;
    height: 50px;
    background: transparent url('../../../assets/images/site/record.svg') center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
  }
  .tone-arm {
    display: inline-block;
    position: absolute;
    left: 48px;
    top: 10px;
    width: 14px;
    transform-origin: top center;
    &.playing {
      animation: moveToneArmOn 0.5s 1 ease-out forwards;
    }
    &.paused {
      animation: moveToneArmOff 0.5s 1 ease-in forwards;
    }
    img {
      max-width: 100%;
      display: block;
    }
  }
  .sticker {
    position: relative;
    top: 17px;
    left: 17px;
    width: 17px;
    border-radius: $large-radius;
    animation: recordSpin 4s infinite linear;
    animation-play-state: paused;
    &.playing {
      animation-play-state: running;
    }
  }
  a {
    color: $orange;
    text-decoration: none;
  }
}

.track-player__details, .track-player__player {
  margin-left: 2rem;
}