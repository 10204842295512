.home {
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 1rem;

  h1 {
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.home__grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(0, auto);
  justify-items: center;
  align-items: center;


  p {
    grid-column: span 2;
  }

  p:nth-of-type(2n) {
    grid-column: 2/4;
  }

}