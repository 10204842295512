@import url('https://fonts.googleapis.com/css?family=Comfortaa');
@import "scss/variables";
@import "scss/buttons";
@import "scss/links";
@import "scss/backgrounds";

body {
  margin: 0;
  padding: 0;
  font-family: Comfortaa, "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  background: #333 url('assets/images/site/texturegrey.jpg');
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
