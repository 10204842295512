@import "../../scss/variables";

.tooltip {
  position: absolute;
  display: block;
  z-index: 1000;
}

.tooltip__text {
  position: relative;
  white-space: nowrap;
  color: $orange;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 1rem;
  border-radius: 3px;
  top: 0.3rem;
}
