.visual_ctrls {
  position: absolute;
  margin: 1rem;
}

.terrain__ctrls {
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: 1rem;
  width: 400px;
  input {
    width: 100%;
  }
}

.acceleration {
  cursor: none;
}