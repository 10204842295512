.header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  padding: 0 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.4);

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center
  }
}

