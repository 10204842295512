.music {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
}

.music__studio {
  grid-column: 1 / 4;
  position: relative;
  line-height: 1rem;
  font-size: 1rem;

  .jr-logo {
    position: absolute;
    top: 8%;
    right: 2%;
    max-height: 80%;
  }
}

.track-list {
  position: relative;
  grid-column: 1/2;
  padding: 1rem;
  @media all and (max-width: 867px) {
    grid-column: 1/4;
  }
}

.selected-track {
  padding: 1rem;
  grid-column: 2/4;
  @media all and (max-width: 867px) {
    grid-column: 1/4;
  }
}

img {
  max-width: 100%;
  display: block;
}
