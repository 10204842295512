@import "_variables.scss";

button {
  padding: 0.5rem 1.6rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: $orange;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: rgba(#fff, 0.1);
  }
  &:active {
    background-color: rgba($orange, 0.1);
  }
  &:disabled {
    background-color: rgba(#f00, 0.1);
  }
}