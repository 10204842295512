@import "../../../scss/animations";

.track-list-item {
  position: relative;
}

.track-list-item__img {
  border-radius: 100%;
  animation: recordSpin 4s infinite linear;
  animation-play-state: running;
  cursor: pointer;
  display: block;
  &.paused {
    animation-play-state: paused;
  }
}