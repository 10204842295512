@import "../../../scss/variables";


.track-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.7rem;
  align-items: center;
  @media all and (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media all and (max-width: 867px) {
    grid-template-columns: repeat(10, 1fr);
  }
  @media all and (max-width: 600px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media all and (max-width: 500px) {
    grid-template-columns: repeat(6, 1fr);
  }
}
