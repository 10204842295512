@import "../../../scss/variables";
.selected-track {
  margin: 1rem 1rem 1rem 0;
}

.selected-track__header {
  display: grid;
  grid-template-columns: 1fr 6fr;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1rem;
  h4 {
    margin: 0.5rem 0;
  }
}

.selected-track__subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-track__img {
  img {
    display: block;
    max-width: 100px;
    border-radius: 100%;
  }
}