.audio-home {
  background-size: contain;
}

.audio-home__content {
  margin: 1rem 1rem 0;
}

.audio-home__grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(0, auto);

  > * {
    margin: 0;
  }

  :nth-child(1) {
    grid-column: 1/3;
  }
  :nth-child(2) {
    grid-row: 2/4;
  }
  :nth-child(3) {
    grid-column: 2/4
  }
}